import React, { useEffect, useState } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { useNavigate, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import Canvas from '../../hooks/Canvas';
import 'react-html5-camera-photo/build/css/index.css';
// import proVideos from '../../videos/index';
import imageScreenFrame from '../../images/Takeaway_Frame_Web.png';

function CaptureScreen() {
  const navigate = useNavigate();
  const [tempCanvas, setTempCanvas] = useState(null);
  const [proVideo, setProVideo] = useState(null);
  const [proSelection, setProSelection] = useState('');
  const [cameraDone, setCameraDone] = useState('');
  const { state } = useLocation();
  const [camDirection, setCamDirection] = useState(null);
  const imageHeight = 693;
  const imageWidth = 390;

  // Also this is dumb, why not match a named route and go there...
  let pathname = `${window.location.pathname}`;
  pathname = pathname.replace(/[^/]*$/, 'review');

  /* For MLB version, assigning extra class depending on a property
  let videoSide = 'videoLeft';  

  if (proVideo && proVideo.src) {
    if (proVideo.src.includes('_R_')) {
      videoSide = 'videoRight';
    }
  }
  */
  
  useEffect(() => {
    // const { proSelect } = state;
    // setProSelection(proSelect);
    setCamDirection('USER');
    setCameraDone('');
    document.body.classList.remove('userCapture');
    document.body.classList.remove('environmentCapture');
    // const pro = proVideos.find((x) => x.proName === proSelect);
    // const v = document.createElement('video');
    // v.src = pro.file;
    // v.width = imageWidth;
    // v.height = imageHeight;
    // v.muted = true;
    // v.autoPlay = false;
    // v.controls = false;
    // v.loop = false;
    
    // v.setAttribute('webkit-playsinline', 'webkit-playsinline');
    // v.setAttribute('playsInline', 'playsInline');
    // setProVideo(v);

    // v.play();

    const c = document.createElement('canvas');
    c.width = imageWidth;
    c.height = imageHeight;
    setTempCanvas(c);
  
    const deleteImage = document.getElementsByClassName('react-html5-camera-photo');
    console.log(deleteImage);
    if (deleteImage[0] && deleteImage[0].getElementsByTagName('img')[0]) {
      console.log(deleteImage[0]);
      console.log(deleteImage[0].getElementsByTagName('img'));
      deleteImage[0].getElementsByTagName('img')[0].remove();
    }
  }, []);

  const startVideo = () => {
    const v = proVideo;
    v.play();
  };

  const shouldHide = (red, green, blue) => blue > 190 && red < 100 && green < 100;

  const draw = (ctx, canvas) => {
    const v = proVideo;
    
    if (proVideo === null || v.paused || v.ended) {
      return;
    }

    if (canvas.width !== v.width || canvas.height !== v.height) {
      canvas.width = v.width;
      canvas.height = v.height;
      tempCanvas.width = v.width;
      tempCanvas.height = v.height;
    }
    
    const offscreenCTX = tempCanvas.getContext('2d', { willReadFrequently: true });
    const cw = canvas.width;
    const ch = canvas.height;
    offscreenCTX.clearRect(0, 0, offscreenCTX.canvas.width, offscreenCTX.canvas.height);
    // Draw the video frame onto the preview video
    // REPLACE HERE to update the frame for the video
    offscreenCTX.drawImage(v, 0, 0, cw, ch);

    const frame = offscreenCTX.getImageData(0, 0, cw, ch);
    const { data } = frame;

    for (let i = 0; i < data.length; i += 4) {
      const red = data[i];
      const green = data[i + 1];
      const blue = data[i + 2];
      if (shouldHide(red, green, blue)) {
        data[i + 3] = 0;
      }
    }
    
    ctx.putImageData(frame, 0, 0);
  };

  const handleTakePhoto = () => {
    if (camDirection === 'USER') {
      setCameraDone('userCapture');
      document.body.classList.add('userCapture');
      document.body.classList.remove('environmentCapture');
    } else {
      setCameraDone('environmentCapture');
      document.body.classList.add('environmentCapture');
      document.body.classList.remove('userCapture');
    }

    html2canvas(document.body).then((canvas) => {
      // document.body.appendChild(canvas);
      const uri = canvas.toDataURL('image/jpeg', 1);
      console.log(uri);
      console.log('routing to review');
      navigate(pathname, { state: { imageDataUri: uri, proSelect: proSelection } });
    });
  };

  const handleSwapCamera = () => {
    if (camDirection === 'USER') {
      setCamDirection('ENVIRONMENT');
    } else {
      setCamDirection('USER');
    }
  };

  return (
    <div className={cameraDone}>
      <div id="capture-container">
        <div 
          className="btn__swap-camera" 
          onTouchEnd={handleSwapCamera}
        >
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 44C19.2333 44 15.0167 42.5 11.35 39.5C7.68333 36.5 5.36667 32.6667 4.4 28H8.5C9.43333 31.5333 11.3333 34.4167 14.2 36.65C17.0667 38.8833 20.3333 40 24 40C26.8667 40 29.5333 39.2913 32 37.874C34.4667 36.4567 36.4 34.4987 37.8 32H32V28H44V40H40V36C38.1 38.5333 35.75 40.5 32.95 41.9C30.15 43.3 27.1667 44 24 44ZM24 30C22.3333 30 20.9167 29.4167 19.75 28.25C18.5833 27.0833 18 25.6667 18 24C18 22.3333 18.5833 20.9167 19.75 19.75C20.9167 18.5833 22.3333 18 24 18C25.6667 18 27.0833 18.5833 28.25 19.75C29.4167 20.9167 30 22.3333 30 24C30 25.6667 29.4167 27.0833 28.25 28.25C27.0833 29.4167 25.6667 30 24 30ZM4 20V8H8V12C9.9 9.46667 12.25 7.5 15.05 6.1C17.85 4.7 20.8333 4 24 4C28.7667 4 32.9833 5.5 36.65 8.5C40.3167 11.5 42.6333 15.3333 43.6 20H39.5C38.5667 16.4667 36.6667 13.5833 33.8 11.35C30.9333 9.11667 27.6667 8 24 8C21.1333 8 18.4667 8.70867 16 10.126C13.5333 11.5433 11.6 13.5013 10.2 16H16V20H4Z" fill="white" />
          </svg>
        </div>
        { camDirection === 'ENVIRONMENT'
          ? (
            <Camera
              isFullscreen
              imageType={IMAGE_TYPES.JPG}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              onTakePhoto={handleTakePhoto} />
          )        
          : (
            <Camera
              isFullscreen
              imageType={IMAGE_TYPES.JPG}
              idealFacingMode={FACING_MODES.USER}
              onTakePhoto={handleTakePhoto} />
          )}
      </div>
      <div 
        id="canvas-container"
        // className={videoSide}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <img src={imageScreenFrame} alt="Frame for Video" />

      </div>
    </div>
  );
}

export default CaptureScreen;

// Canvas goes where the img is now and adds a video playing over the camera preview image
// <Canvas draw={draw} />
