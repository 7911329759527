import React, { useRef, useEffect } from 'react';

function Canvas(props) {
  const { draw, ...rest } = props;
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let animationFrameId;

    const render = () => {
      draw(context, canvas);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return (
    <canvas
      width={600}
      height={1066}
      ref={canvasRef}
      {...rest} />
  );
}

export default Canvas;
