import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// Video Import Example
// import attractLoopVideo from '../../videos/Attract_Loop_Mobile_sm_1080p.mp4';
import adLoopVideo from '../../videos/Coca_Cola_Spiced_Glitch_Video_Trim_1080.mp4';
import imagePromptDesktop from '../../images/ccs-hero.jpg';

function AdScreen() {
  const navigate = useNavigate();
  // Also this is dumb, why not match a named route and go there...
  let pathname = `${window.location.pathname}`;
  pathname = pathname.replace(/[^/]*$/, 'capture');
  console.log(pathname);
  const start = () => navigate(pathname);
  const [buttonShow, setButtonShow] = useState(0);
  const [videoHide, setVideoHide] = useState(1);

  useEffect(() => {
    const timerButton = setTimeout(() => setButtonShow(1), 8700);
    const timerVideo = setTimeout(() => setVideoHide(0), 8700);
    return () => {
      clearTimeout(timerButton);
      clearTimeout(timerVideo);
    };
  }, []);
  
  return (
    <div 
      className="App__inner"
      style={{
        position: 'relative',
        minHeight: '500px',
      }}>
      <div 
        className="section__absolute section__background--black"
        style={{
          opacity: videoHide,
        }}>
        <video
          id="ad-video"
          muted
          playsInline
          autoPlay
          width="100%"
          style={{
            minHeight: '660px',
            marginTop: '-30px',
          }}>
          <source
            src={adLoopVideo}
            type="video/mp4" />
        </video>
      </div>

      <div className="section__absolute">
        <p 
          className="text__large-header"
          style={{
            opacity: buttonShow,
            fontSize: '24px',
          }}>
          Get ready to express how 
          <br />
          Coca-Cola® Spiced makes you feel.
        </p>

        <Button
          variant="contained"
          color="primary"
          onTouchEnd={start}
          className="button__ready-button"
          sx={{
            borderRadius: 8,
            textTransform: 'none',
            fontSize: '24px',
            lineHeight: '29px',
            fontWeight: 700,
            opacity: buttonShow,
          }}>
          Tap When
          <br />
          Ready
        </Button>
      </div>
    </div>
  );
}

export default AdScreen;

/* Example Video Full-Screen
<div style={{
  height: '100%',
  backgroundColor: 'white',
}}>
  <video
    id="idle-video"
    muted
    playsInline
    autoPlay
    width="100%"
    loop 
    style={{
      minHeight: '660px',
    }}>
    <source
      src={attractLoopVideo}
      type="video/mp4" />
  </video>
</div> */
