import React from 'react';

import './styles/imagePreview.css';

export function ImagePreview({ dataUri, isFullscreen }) {
  const classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

  return (
    <div className={`demo-image-preview ${classNameFullscreen}`}>
      <img
        src={dataUri}
        alt="" />
    </div>
  );
}

export default ImagePreview;
